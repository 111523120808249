import { match } from 'react-router-dom';

import { ROLE_MENUITEMS } from 'constants/permissions';
import UserPermissionsStore from 'stores/UserPermissionsStore';

export const canActivateOrigins = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(ROLE_MENUITEMS.SUPER_ADMIN) ||
  userPermissionsStore.hasConfigAdminInOrigin;

export const canAddOrigin = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.canEditCurrentPartner;

export const canAddCareUnit = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.canEditCurrentPartner;

export const canViewAccountDeletion = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(ROLE_MENUITEMS.SUPER_ADMIN, ROLE_MENUITEMS.CONFIG_ADMIN);

export const canActivateCareUnits = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(ROLE_MENUITEMS.SUPER_ADMIN) ||
  userPermissionsStore.hasConfigAdminInCareUnit;

export const canActivateSpecificCareUnits =
  (userPermissionsStore: UserPermissionsStore) =>
  ({ params }: match) => {
    // todo: pick careUnitId from params
    console.log('params:::', params);
    return (
      userPermissionsStore.getSideBarAccess(ROLE_MENUITEMS.SUPER_ADMIN) ||
      userPermissionsStore.hasConfigAdminInCareUnit
    );
  };

export const canActivateContent24 = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(
    ROLE_MENUITEMS.MEDICAL_CONTENT_VIEWER,
    ROLE_MENUITEMS.MEDICAL_CONTENT_EDITOR,
    ROLE_MENUITEMS.SUPER_ADMIN
  );

export const canActivatePractitioners = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(
    ROLE_MENUITEMS.CLINIC_USER_ADMIN,
    ROLE_MENUITEMS.CONFIG_ADMIN,
    ROLE_MENUITEMS.SUPER_ADMIN
  );

export const canViewRules = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(
    ROLE_MENUITEMS.BUSINESS_RULES_VIEWER,
    ROLE_MENUITEMS.BUSINESS_RULES_EDITOR,
    ROLE_MENUITEMS.SUPER_ADMIN
  );

export const canViewCarePathways = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.getSideBarAccess(
    ROLE_MENUITEMS.PATHWAY_EDITOR,
    ROLE_MENUITEMS.PATHWAY_VIEWER,
    ROLE_MENUITEMS.PATHWAY_ADVANCED_EDITOR,
    ROLE_MENUITEMS.SUPER_ADMIN
  );

export const isSuperAdmin = (userPermissionsStore: UserPermissionsStore) =>
  userPermissionsStore.isSuperAdmin;

export const canActivateCareProviders =
  (userPermissionsStore: UserPermissionsStore) =>
  ({ params }: match) => {
    const { careProviderId } = params as { careProviderId: string };
    return (
      userPermissionsStore.getSideBarAccess(ROLE_MENUITEMS.SUPER_ADMIN) ||
      userPermissionsStore.canEditCareProvider(careProviderId)
    );
  };
